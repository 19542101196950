/* eslint-disable  */


export const API_EVENTS = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  RESET: 'RESET',
  DONE: 'DONE',
};

export const REVENUE_CATEGORIES_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const CLASS_CATEGORIES_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const STAFF_PAYRATES_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL', 
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const STAFF_MEMBER_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  SET: 'SET',
};
export const CLASS_PACK_EVENTS = {
  DELETE_SUCCESS: 'DELETE_SUCCESS',
};
export const MEMBERSHIP_EVENTS = {
  DELETE_SUCCESS: 'DELETE_SUCCESS',
};
export const UNSAVE_ALERT_EVENTS = {
  SET: 'SET',
  RESET: 'RESET',
};

export const NETWORK_EVENTS = {
  NETWORK_CALL_REQUEST: 'NETWORK_CALL_REQUEST',
  NETWORK_CALL_SUCCESS: 'NETWORK_CALL_SUCCESS',
  NETWORK_CALL_FAIL: 'NETWORK_CALL_FAIL',
  NETWORK_CALL_RESET: 'NETWORK_CALL_RESET',
};

export const AUTH_EVENTS = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_RESET: 'LOGIN_RESET',
  VALIDATE_REQUEST: 'VALIDATE_REQUEST',
  VALIDATE_SUCCESS: 'VALIDATE_SUCCESS',
  VALIDATE_FAIL: 'VALIDATE_FAIL',
};

export const TIMEZONE_EVENTS = API_EVENTS;
export const STATES_EVENTS = API_EVENTS;

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_EVENTS = {
  SHOW_NETWORK_CALL_FAIL_TOAST: 'SHOW_NETWORK_CALL_FAIL_TOAST',
  HIDE_NETWORK_CALL_FAIL_TOAST: 'HIDE_NETWORK_CALL_FAIL_TOAST',
  SHOW_MESSAGE_TOAST: 'SHOW_MESSAGE_TOAST',
  HIDE_MESSAGE_TOAST: 'HIDE_MESSAGE_TOAST',
};

export const DELETE_HOURS = {
  REQUEST: 'DELETE_HOURS_REQUEST',
  SUCCESS: 'DELETE_HOURS_SUCCESS',
  FAIL: 'DELETE_HOURS_FAIL',
};

export const REMOVE_MEDIA_EVENTS = {
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const GET_STAFF_USERS = {
  STAFF_REQUEST: 'STAFF_REQUEST',
  STAFF_SUCCESS: 'STAFF_SUCCESS',
  STAFF_FAIL: 'STAFF_FAIL',
};

export const GET_STUDIO_ADDITIONAL_DATA = {
  DATA_REQUEST: 'DATA_REQUEST',
  DATA_SUCCESS: 'DATA_SUCCESS',
  DATA_FAIL: 'DATA_FAIL',
};

export const LOAD_COMPANY_TAX = {
  TAX_REQUEST: 'TAX_REQUEST',
  TAX_SUCCESS: 'TAX_SUCCESS',
  TAX_FAIL: 'TAX_FAIL',
};

export const FITNESS_CLASS_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  EDIT_REQUEST: 'EDIT_REQUEST',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_FAIL: 'EDIT_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const EMAIL_SETTINGS_EVENTS = {
  TRIGGERS_REQUEST: 'TRIGGERS_REQUEST',
  TRIGGERS_SUCCESS: 'TRIGGERS_SUCCESS',
  TRIGGERS_FAIL: 'TRIGGERS_FAIL',
  EMAIL_SIGNATURE_REQUEST: 'EMAIL_SIGNATURE_REQUEST',
  EMAIL_SIGNATURE_SUCCESS: 'EMAIL_SIGNATURE_SUCCESS',
  EMAIL_SIGNATURE_FAIL: 'EMAIL_SIGNATURE_FAIL',
  REMOVE_SIGNATURE_REQUEST: 'REMOVE_SIGNATURE_REQUEST',
  REMOVE_SIGNATURE_SUCCESS: 'REMOVE_SIGNATURE_SUCCESS',
  REMOVE_SIGNATURE_FAIL: 'REMOVE_SIGNATURE_FAIL',
};

export const CLASS_INSTANCE_EVENTS = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  RESET: 'RESET',
  SET: 'SET',
  SET_CLASSES_FILTER: 'SET_CLASSES_FILTER',
  SET_INSTRUCTOR_FILTER: 'SET_INSTRUCTOR_FILTER',
  SET_STUDIO_FILTER: 'SET_STUDIO_FILTER',
  SET_TIMEPERIOD_FILTER: 'SET_TIMEPERIOD_FILTER',
  RESET_FILTER: 'RESET_FILTER',
};

export const STUDIO_EVENTS = {
  SET: 'SET',
  LOAD_STUDIO_LIST: 'LOAD_STUDIO_LIST',
  DELETE_STUDIO: 'DELETE_STUDIO',
};

export const PROMOCODE_EVENTS = {
  SET: 'SET',
  LOAD_PROMOCODE_LIST: 'LOAD_PROMOCODE_LIST',
  DELETE_PROMOCODE: 'DELETE_PROMOCODE',
  RESET_MODAL: 'RESET_MODAL',
  SET_MODAL: 'SET_MODAL',
  LOAD_DATA: 'LOAD_DATA',
};

export const ALERT_EVENTS = {
  SET: 'SET',
  RESET: 'RESET',
};

export const APP_UTILITIES_EVENTS = {
  SET: 'SET',
  RESET: 'RESET',
};

export const SOLO_INSTRUCTOR_EVENTS = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  RESET: 'RESET',
  SET: 'SET',
};

export const DISCOUNT = {
  SUCCESS: 'DISCOUNT_SUCCESS',
  FAIL: 'FAIL',
};

export const CLASS_INSTANCE_INSIGHTS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  REQUEST: 'REQUEST',
  SET: 'SET',
};
