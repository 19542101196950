/* eslint-disable no-restricted-syntax */
import { API_EVENTS, DELETE_HOURS } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  data: null,
  error: null,
};

const hoursReducer = (state, { type, payload }) => {
  switch (type) {
    case API_EVENTS.REQUEST:
    case DELETE_HOURS.REQUEST:
      return { ...state, loading: true };

    case API_EVENTS.SUCCESS: {
      return { ...state, loading: false, data: payload };
    }

    case API_EVENTS.FAIL:
    case DELETE_HOURS.FAIL:
      return { ...state, loading: false, error: payload };

    case API_EVENTS.RESET:
      return initialState;

    case 'ADD_HOURS': {
      console.log('hoursReducer -> if');
      if (state.data) {
        if (payload.peakHour && state.data.peakHour) {
          let newPeakHour = state.data.peakHour;
          console.log('hoursReducer -> if', newPeakHour);
          for (const [key, values] of Object.entries(payload.peakHour)) {
            if (state.data.peakHour[key]) {
              newPeakHour = { ...newPeakHour, [key]: [...state.data.peakHour[key], ...values] };
            } else {
              console.log('hoursReducer -> newPeakHour', newPeakHour);
              newPeakHour = { ...newPeakHour, [key]: values };
            }
          }
          return {
            data: {
              ...state.data,
              peakHour: newPeakHour,
              isPeakNonPeakAllowed: '1',
            },
          };
        }
        if (payload.nonPeakHour && state.data.nonPeakHour) {
          console.log('nonPeak====>');
          let newPeakHour = state.data.nonPeakHour;
          for (const [key, values] of Object.entries(payload.nonPeakHour)) {
            if (state.data.nonPeakHour[key]) {
              newPeakHour = { ...newPeakHour, [key]: [...values, ...state.data.nonPeakHour[key]] };
            } else {
              newPeakHour = { ...newPeakHour, [key]: values };
            }
          }
          return {
            data: {
              ...state.data,
              nonPeakHour: newPeakHour,
              isPeakNonPeakAllowed: '1',
            },
          };
        }
        console.log('peak/nonPeak====>', state.data);
        return { data: { ...(state.data || {}), ...payload, isPeakNonPeakAllowed: '1' } };
      }
      const updateData = { data: { ...payload, isPeakNonPeakAllowed: '1' } };
      console.log('Payload====>', payload, updateData);
      return updateData;
    }

    case 'EDIT_HOURS': {
      console.log('hoursReducer -> payload', payload);
      const updatedData = state.data[payload.type];
      updatedData[payload.day] = payload.startEndTime;
      return { ...state, data: { ...state.data, [payload.type]: updatedData } };
    }

    case DELETE_HOURS.SUCCESS: {
      if (payload.type && payload.day) {
        const updatedData = state.data[payload.type];
        delete updatedData[payload.day];
        return { ...state, data: { ...state.data, [payload.type]: updatedData } };
      }
      return state;
    }

    default:
      return state;
  }
};

export default hoursReducer;
