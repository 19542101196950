/* eslint-disable  */
const initialState = {
  user: {},
  loggedInUserInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER':
      return { ...state, user: action.payload };
    case 'LOGGED_IN_USER_INFO':
      return { ...state, loggedInUserInfo: action.payload };
    default:
      return state;
  }
};

export default reducer;
