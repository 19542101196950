/* eslint-disable  */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import ajax from '../utils/ajaxHelper';
import { getHostType, getUserId, getCompanyId } from '../utils';

const useLogin = (toogleModal) => {
  const { company, token, roleId } = useParams();
  const navigate = useNavigate();
  const { pathname, ...rest } = useLocation();
  const dispatch = useDispatch();
  const hosttype =
    roleId === 'solo-instructor' || roleId === 'solo-client'
      ? 'solo-instructor'
      : getHostType(roleId);

  const callAPi = useCallback(
    async (url, values, { resetForm, setErrors, setStatus, setSubmitting }) => {
      setSubmitting(true);
      let data = { ...values, roleId };
      try {
        let companyDetail = {};
        if (company) {
          const res = await ajax.post(
            'checkCompany',
            {
              company,
              roleId,
            },
            {
              networkParams: { showLoader: false },
              headers: {
                hosttype,
              },
            },
          );
          dispatch({ type: 'USER', payload: res });
          companyDetail = { companyId: res.companyId };
        }
        if (token) {
          await ajax.post(
            'passwordTokenValidate',
            {
              token,
            },
            {
              networkParams: { showLoader: false },
              headers: {
                ...companyDetail,
                hosttype: getHostType(roleId),
              },
            },
          );
          data = { ...values, token };
        }

        const res = await ajax.post(url, data, {
          networkParams: { showLoader: false },
          headers: {
            ...companyDetail,
            hosttype: getHostType(roleId),
          },
        });
        if (
          pathname === `/${company}/admin/login` ||
          pathname === `/${company}/solo-instructor/login`
        ) {
          localStorage.clear('clientsTabQueryData');

          if (res.onBoardingData.length > 0) {
            navigate(`../app/settings/${res.onBoardingData[0]}`);
          } else {
            navigate('../app');
          }
        } else if (roleId === 'user') {
          navigate('../../calendar');
        } else if (roleId === 'solo-client' && url == 'resetPasswordMail') {
          setTimeout(function () {
            navigate('../login');
          }, 2000);
        } else if (roleId === 'solo-client' && url !== 'authResetpassword') {
          if (
            (window.location.href.indexOf('?details=true') > -1 ||
              sessionStorage.getItem('classInstanceId') ||
              sessionStorage.getItem('isABI')) &&
            res.isSpecial === 1
          ) {
            const classInstanceId =
              sessionStorage.getItem('classInstanceId') ||
              window.location.href.split('classInstanceId=')[1];
            sessionStorage.setItem(
              'classInstanceId',
              sessionStorage.getItem('classInstanceId') ||
                window.location.href.split('classInstanceId=')[1],
            );

            navigate(
              (sessionStorage.getItem('isABI') ? '../event/book-event/' : '../classes/details/') +
                classInstanceId,
            );
          } else {
            navigate('..');
          }
        } else if (url !== 'authResetpassword' && url !== 'resetpassword') {
          setTimeout(function () {
            navigate('../app');
          }, 2000);
        }

        resetForm();
        setStatus({ success: true });
      } catch (error) {
        setStatus({ success: false });
        setErrors({ submit: error.message });
        throw error;
      } finally {
        setSubmitting(false);
      }
    },
    [hosttype, token, company, roleId, pathname, navigate],
  );

  const login = useCallback(
    async (values, actions) => {
      try {
        await callAPi('login', values, actions);
      } catch (error) {
        console.log('error', error);
      }
    },
    [callAPi],
  );

  const resetPasswordMail = useCallback(
    async (values, actions) => {
      const companyId = getCompanyId();
      await callAPi('resetPasswordMail', { ...values, companyId }, actions);
    },
    [callAPi],
  );

  const resetpassword = useCallback(
    async (values, actions) => {
      let data = values;
      const companyId = getCompanyId();
      const userId = getUserId();
      if (toogleModal) {
        data = { ...data, userId, companyId };
      }

      await callAPi(toogleModal ? 'authResetpassword' : 'resetpassword', data, actions, {});
      if (toogleModal) {
        toogleModal();
      } else {
        // alert();
        navigate('../../login');
      }
    },
    [callAPi, navigate, toogleModal],
  );

  const logout = useCallback(async () => {
    try {
      await ajax.post('logout');
    } catch (error) {
      console.log(error);
    } finally {
      Cookies.remove('token');
      Cookies.remove('hosttype');
      Cookies.remove('user');

      localStorage.clear('clientsTabQueryData');

      dispatch({ type: 'USER', payload: {} });

      const currentUrl = window.location.href;
      let loginURL = '/login';
      if (currentUrl?.includes('solo-instructor')) {
        loginURL = `/${company}/solo-instructor/login`;
      }
      if (currentUrl?.includes('solo-client')) {
        loginURL = `/${company}/solo-client/client/app/login`;
      }
      // console.log('loginURL', 'useAuth', loginURL);
      navigate(loginURL);
      /* if (roleId === 'solo-client') {
        navigate('login');
      } else {
        // navigate('../login');
        location.reload();
      } */
    }
  }, [navigate, roleId]);

  const logoutNew = useCallback(async () => {
    try {
      await ajax.post('logout');
    } catch (error) {
      console.log(error);
    } finally {
      let path = pathname; // "/arindamabpstg/solo-client/client/app/";
      let parts = path.split('/');
      let companyName = parts[1];
      let roleType = parts[2];

      Cookies.remove('token');
      Cookies.remove('hosttype');
      Cookies.remove('user');

      localStorage.clear('clientsTabQueryData');

      dispatch({ type: 'USER', payload: {} });

      const currentUrl = window.location.href;

      if (roleType == 'solo-instructor') {
        // http://localhost:3000/#/arindamabpstg/solo-instructor/login
        let redirectUrl = `${companyName}/${roleType}/login`;
        navigate(redirectUrl);
      } else if (roleType == 'solo-client') {
        // http://localhost:3000/#/arindamabpstg/solo-client/client/app/login
        let redirectUrl = `${companyName}/${roleType}/client/app/login`;
        navigate(redirectUrl);
      } else {
        let redirectUrl = `/login`;
        navigate(redirectUrl);
      }
    }
  }, [navigate]);

  const requestCompanyId = useCallback(
    async (callback = null) => {
      if (company) {
        const res = await ajax.post(
          'checkCompany',
          {
            company,
          },
          {
            networkParams: { showLoader: false },
            headers: {
              hosttype,
            },
          },
        );
        dispatch({ type: 'USER', payload: res });
        if (res?.companyId && callback) {
          callback();
        }
        return res?.companyId;
      }
      return null;
    },
    [hosttype, company],
  );

  const fetchCompanyId = useCallback(async () => {
    const companyId = getCompanyId();
    if (companyId) return companyId;
    return requestCompanyId();
  }, [requestCompanyId]);

  return {
    login,
    resetPasswordMail,
    resetpassword,
    logout,
    logoutNew,
    fetchCompanyId,
    requestCompanyId,
  };
};

export default useLogin;
