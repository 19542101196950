import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import hoursReducer, { initialState } from '../reducers/hoursReducer';

export const HoursContext = createContext();

const HoursProvider = ({ children }) => {
  const useHoursState = useReducer(hoursReducer, initialState);

  return (
    <HoursContext.Provider value={[...useHoursState]}>
      <ContextDevTool context={HoursContext} id="Hours Context" displayName="Hours Context" />
      {children}
    </HoursContext.Provider>
  );
};

HoursProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HoursProvider;
