/* eslint-disable  */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  paper: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(4),
    width: 1024,
    marginTop: '70px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      marginTop: '55px',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing(4),
    position: 'relative',
  },
  customWidth: {
    maxWidth: 200,
    backgroundColor: '#353755',
    fontSize: '14px',
  },
  customArrow: {
    color: '#353755',
  },
  downloadButton:{
    color: 'blue'
  }
}));

export default useStyles;
