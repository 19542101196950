export const drawerWidth = 240;

export const roles = {
  Uber: 'Uber',
  Company: 'Company',
  Client: 'Client',
  SoloClient: 'solo-client',
  SoloInstructor: 'solo-instructor',
};

export const a = 'a';

export const CALENDAR = 'CALENDAR';

export const IMAGEBASEPATH =
  window.location.host.indexOf('localhost') > -1
    ? `http://localhost:2100/`
    : `${window.location.protocol}//${window.location.host}/api/`;

export const API_BASE_URL = window.location.hostname === 'localhost' ? '' : 'api';

export const ABI_BASE_URL =
  window.location.hostname === 'oursmartstudio.com' ||
  window.location.hostname === 'oss-events.beltline.org'
    ? 'https://beltline.org/'
    : 'https://fe-staging.beltline.org/';
export const ABP_BASE_URL = 'https://beltline.org/';
export const EventType = {
  FitnessAndWellness: '66967da27bfe7353f6515551',
  ArtsAndCulture: '65eb6b23e7acf78098fce6bb',
  BoardMeeting: '65eb6b99e7acf78098fce6bd',
  Ceremonies: '65eb6bb5e7acf78098fce6bf',
  CommunityEvents: '65eb6bcde7acf78098fce6c1',
  VolunteerEvents: '65eb6be4e7acf78098fce6c3',
  BusinessSupport: '65f40b4fe7acf78098fce6d2',
  ResidentSupport: '660b125574bae5f535a3e3bb',
};

export const IncomeOptions = [
  { value: 'lessThan25000', label: 'Less than $25,000' },
  { value: 'between25000-49999', label: '$25,000 - $49,999' },
  { value: 'between50000-99999', label: '$50,000 - $99,999' },
  { value: 'between100000-199999', label: '$100,000 - $199,999' },
  { value: '200000OrMore', label: '$200,000 or more' },
];

export const neighborhoodList = [
  { neighborhood: 'Adair Park', npu: 'V' },
  { neighborhood: 'Adams Park', npu: 'R' },
  { neighborhood: 'Adamsville', npu: 'H' },
  { neighborhood: 'Almond Park', npu: 'G' },
  { neighborhood: 'Amal Heights', npu: 'Y' },
  { neighborhood: 'Ansley Park', npu: 'E' },
  { neighborhood: 'Arden/Habersham', npu: 'C' },
  { neighborhood: 'Ardmore', npu: 'E' },
  { neighborhood: 'Argonne Forest', npu: 'C' },
  { neighborhood: 'Arlington Estates', npu: 'P' },
  { neighborhood: 'Ashley Courts', npu: 'P' },
  { neighborhood: 'Ashview Heights', npu: 'T' },
  { neighborhood: 'Atkins Park', npu: 'F' },
  { neighborhood: 'Atlanta Industrial Park', npu: 'G' },
  { neighborhood: 'Atlanta University Center', npu: 'T' },
  { neighborhood: 'Atlantic Station', npu: 'E' },
  { neighborhood: 'Audubon Forest', npu: 'I' },
  { neighborhood: 'Audubon Forest West', npu: 'I' },
  { neighborhood: 'Baker Hills', npu: 'H' },
  { neighborhood: 'Baker Hills at Cambellton', npu: 'R' },
  { neighborhood: 'Bakers Ferry', npu: 'H' },
  { neighborhood: 'Bankhead', npu: 'K' },
  { neighborhood: 'Bankhead Courts', npu: 'H' },
  { neighborhood: 'Bankhead/Bolton', npu: 'H' },
  { neighborhood: 'Beecher Hills', npu: 'I' },
  { neighborhood: 'Ben Hill', npu: 'P' },
  { neighborhood: 'Ben Hill Acres', npu: 'P' },
  { neighborhood: 'Ben Hill Forest', npu: 'P' },
  { neighborhood: 'Ben Hill Pines', npu: 'P' },
  { neighborhood: 'Ben Hill Terrace', npu: 'P' },
  { neighborhood: 'Benteen Park', npu: 'W' },
  { neighborhood: 'Berkeley Park', npu: 'D' },
  { neighborhood: 'Betmar LaVilla', npu: 'Y' },
  { neighborhood: 'Blair Villa/Poole Creek', npu: 'Z' },
  { neighborhood: 'Blandtown', npu: 'D' },
  { neighborhood: 'Bolton', npu: 'D' },
  { neighborhood: 'Bolton Hills', npu: 'G' },
  { neighborhood: 'Bonnybrook Estates', npu: 'R' },
  { neighborhood: 'Boulder Park', npu: 'H' },
  { neighborhood: 'Boulevard Heights', npu: 'W' },
  { neighborhood: 'Brandon', npu: 'C' },
  { neighborhood: 'Brentwood', npu: 'P' },
  { neighborhood: 'Briar Glen', npu: 'P' },
  { neighborhood: 'Brookhaven', npu: 'B' },
  { neighborhood: 'Brookview Heights', npu: 'G' },
  { neighborhood: 'Brookwood', npu: 'E' },
  { neighborhood: 'Brookwood Hills', npu: 'E' },
  { neighborhood: 'Browns Mill Park', npu: 'Z' },
  { neighborhood: 'Buckhead Forest', npu: 'B' },
  { neighborhood: 'Buckhead Village', npu: 'B' },
  { neighborhood: 'Bush Mountain', npu: 'S' },
  { neighborhood: 'Butner/Tell', npu: 'P' },
  { neighborhood: 'Cabbagetown', npu: 'N' },
  { neighborhood: 'Campbellton Road', npu: 'R' },
  { neighborhood: 'Candler Park', npu: 'N' },
  { neighborhood: 'Capitol Gateway', npu: 'V' },
  { neighborhood: 'Capitol View', npu: 'X' },
  { neighborhood: 'Capitol View Manor', npu: 'X' },
  { neighborhood: 'Carey Park', npu: 'G' },
  { neighborhood: 'Carroll Heights', npu: 'H' },
  { neighborhood: 'Carver Hills', npu: 'G' },
  { neighborhood: 'Cascade Avenue/Road', npu: 'S' },
  { neighborhood: 'Cascade Green', npu: 'P' },
  { neighborhood: 'Cascade Heights', npu: 'I' },
  { neighborhood: 'Castleberry Hill', npu: 'M' },
  { neighborhood: 'Castlewood', npu: 'C' },
  { neighborhood: 'Center Hill', npu: 'J' },
  { neighborhood: 'Chalet Woods', npu: 'I' },
  { neighborhood: 'Channing Valley', npu: 'C' },
  { neighborhood: 'Chastain Park', npu: 'A' },
  { neighborhood: 'Chattahoochee', npu: 'G' },
  { neighborhood: 'Chosewood Park', npu: 'Y' },
  { neighborhood: 'Collier Heights', npu: 'I' },
  { neighborhood: 'Collier Hills', npu: 'C' },
  { neighborhood: 'Collier Hills North', npu: 'C' },
  { neighborhood: 'Colonial Homes', npu: 'C' },
  { neighborhood: 'Continental Colony', npu: 'R' },
  { neighborhood: 'Cross Creek', npu: 'C' },
  { neighborhood: 'Custer/McDonough/Guice', npu: 'W' },
  { neighborhood: 'Deerwood', npu: 'P' },
  { neighborhood: 'Dixie Hills', npu: 'J' },
  { neighborhood: 'Downtown', npu: 'M' },
  { neighborhood: 'Druid Hills', npu: 'N' },
  { neighborhood: 'East Ardley Road', npu: 'I' },
  { neighborhood: 'East Atlanta', npu: 'W' },
  { neighborhood: 'East Chastain Park', npu: 'B' },
  { neighborhood: 'East Lake', npu: 'O' },
  { neighborhood: 'Edgewood', npu: 'O' },
  { neighborhood: 'Elmco Estates', npu: 'P' },
  { neighborhood: 'Englewood Manor', npu: 'Y' },
  { neighborhood: 'English Avenue', npu: 'L' },
  { neighborhood: 'English Park', npu: 'G' },
  { neighborhood: 'Fairburn', npu: 'P' },
  { neighborhood: 'Fairburn Heights', npu: 'H' },
  { neighborhood: 'Fairburn Road/Wisteria Lane', npu: 'H' },
  { neighborhood: 'Fairburn Tell', npu: 'P' },
  { neighborhood: 'Fairburn Mays', npu: 'H' },
  { neighborhood: 'Fairway Acres', npu: 'P' },
  { neighborhood: 'Fernleaf', npu: 'C' },
  { neighborhood: 'Florida Heights', npu: 'I' },
  { neighborhood: 'Fort McPherson', npu: 'S' },
  { neighborhood: 'Fort Valley', npu: 'R' },
  { neighborhood: 'Garden Hills', npu: 'B' },
  { neighborhood: 'Georgia Tech', npu: 'E' },
  { neighborhood: 'Glenrose Heights', npu: 'Z' },
  { neighborhood: 'Grant Park', npu: 'W' },
  { neighborhood: 'Green Acres Valley', npu: 'I' },
  { neighborhood: 'Green Forest Acres', npu: 'I' },
  { neighborhood: 'Greenbriar', npu: 'R' },
  { neighborhood: 'Greenbriar Village', npu: 'P' },
  { neighborhood: 'Grove Park', npu: 'J' },
  { neighborhood: 'Hammond Park', npu: 'X' },
  { neighborhood: 'Hanover West', npu: 'C' },
  { neighborhood: 'Harland Terrace', npu: 'I' },
  { neighborhood: 'Harris Chiles', npu: 'T' },
  { neighborhood: 'Harvel Homes Community', npu: 'J' },
  { neighborhood: 'Heritage Valley', npu: 'P' },
  { neighborhood: 'High Point', npu: 'Y' },
  { neighborhood: 'Hills Park', npu: 'D' },
  { neighborhood: 'Home Park', npu: 'E' },
  { neighborhood: 'Horseshoe Community', npu: 'I' },
  { neighborhood: 'Hunter Hills', npu: 'K' },
  { neighborhood: 'Huntington', npu: 'P' },
  { neighborhood: 'Inman Park', npu: 'N' },
  { neighborhood: 'Ivan Hill', npu: 'I' },
  { neighborhood: 'Joyland', npu: 'Y' },
  { neighborhood: 'Just Us', npu: 'T' },
  { neighborhood: 'Kings Forest', npu: 'P' },
  { neighborhood: 'Kingswood', npu: 'A' },
  { neighborhood: 'Kirkwood', npu: 'O' },
  { neighborhood: 'Knight Park/Howell Station', npu: 'K' },
  { neighborhood: 'Lake Clair', npu: 'N' },
  { neighborhood: 'Lake Estates', npu: 'P' },
  { neighborhood: 'Lakewood', npu: 'Z' },
  { neighborhood: 'Lakewood Heights', npu: 'Y' },
  { neighborhood: 'Laurens Valley', npu: 'R' },
  { neighborhood: 'Leila Valley', npu: 'Z' },
  { neighborhood: 'Lenox', npu: 'B' },
  { neighborhood: 'Lincoln Homes', npu: 'G' },
  { neighborhood: 'Lindbergh/Morosgo', npu: 'B' },
  { neighborhood: 'Lindridge/Martin Manor', npu: 'F' },
  { neighborhood: 'Loring Heights', npu: 'E' },
  { neighborhood: 'Magnum Manor', npu: 'I' },
  { neighborhood: 'Margaret Mitchell', npu: 'A' },
  { neighborhood: 'Marietta Street Artery', npu: 'E' },
  { neighborhood: 'Mays', npu: 'H' },
  { neighborhood: 'Meadowbrook Forest', npu: 'P' },
  { neighborhood: 'Mechanicsville', npu: 'V' },
  { neighborhood: 'Mellwood', npu: 'P' },
  { neighborhood: 'Memorial Park', npu: 'C' },
  { neighborhood: 'Midtown', npu: 'E' },
  { neighborhood: 'Midwest Cascade', npu: 'Q' },
  { neighborhood: 'Monroe Heights', npu: 'G' },
  { neighborhood: 'Morningside/Lenox Park', npu: 'F' },
  { neighborhood: 'Mozley Park', npu: 'K' },
  { neighborhood: 'Mt. Gilead Woods', npu: 'P' },
  { neighborhood: 'Mt. Paran Parkway', npu: 'A' },
  { neighborhood: 'Mt. Paran/Northside', npu: 'A' },
  { neighborhood: 'Niskey Cove', npu: 'P' },
  { neighborhood: 'Niskey Lake', npu: 'P' },
  { neighborhood: 'North Buckhead', npu: 'B' },
  { neighborhood: 'Norwood Manor', npu: 'Z' },
  { neighborhood: 'Oakcliff', npu: 'H' },
  { neighborhood: 'Oakland', npu: 'W' },
  { neighborhood: 'Oakland City', npu: 'S' },
  { neighborhood: 'Old Fairburn Village', npu: 'P' },
  { neighborhood: 'Old Fourth Ward', npu: 'M' },
  { neighborhood: 'Old Gordon', npu: 'H' },
  { neighborhood: 'Orchard Knob', npu: 'Z' },
  { neighborhood: 'Ormewood Park', npu: 'W' },
  { neighborhood: 'Paces', npu: 'A' },
  { neighborhood: 'Pamona Park', npu: 'R' },
  { neighborhood: 'Peachtree Battle Alliance', npu: 'C' },
  { neighborhood: 'Peachtree Heights East', npu: 'B' },
  { neighborhood: 'Peachtree Heights West', npu: 'B' },
  { neighborhood: 'Peachtree Hills', npu: 'B' },
  { neighborhood: 'Peachtree Park', npu: 'B' },
  { neighborhood: 'Penelope Neighbors', npu: 'J' },
  { neighborhood: 'Peoplestown', npu: 'V' },
  { neighborhood: 'Perkerson', npu: 'X' },
  { neighborhood: 'Peyton Forest', npu: 'I' },
  { neighborhood: 'Piedmont Heights', npu: 'F' },
  { neighborhood: 'Pine Hills', npu: 'B' },
  { neighborhood: 'Pittsburgh', npu: 'V' },
  { neighborhood: 'Pleasant Hill', npu: 'A' },
  { neighborhood: 'Polar Rock', npu: 'Z' },
  { neighborhood: 'Poncey-Highland', npu: 'N' },
  { neighborhood: 'Princeton Lakes', npu: 'P' },
  { neighborhood: 'Randall Mill', npu: 'A' },
  { neighborhood: 'Rebel Valley Forest', npu: 'Z' },
  { neighborhood: 'Regency Trace', npu: 'Q' },
  { neighborhood: 'Reynoldstown', npu: 'N' },
  { neighborhood: 'Ridgecrest Forest', npu: 'H' },
  { neighborhood: 'Ridgedale Park', npu: 'B' },
  { neighborhood: 'Ridgewood Heights', npu: 'C' },
  { neighborhood: 'Riverside', npu: 'D' },
  { neighborhood: 'Rockdale', npu: 'G' },
  { neighborhood: 'Rosedale Heights', npu: 'Z' },
  { neighborhood: 'Rosalie H. Wright Community Council', npu: 'I' },
  { neighborhood: 'Rue Royal', npu: 'P' },
  { neighborhood: 'Sandlewood Estates', npu: 'P' },
  { neighborhood: 'Scotts Crossing', npu: 'G' },
  { neighborhood: 'Sherwood Forest', npu: 'E' },
  { neighborhood: 'South Atlanta', npu: 'Y' },
  { neighborhood: 'South River Gardens', npu: 'Z' },
  { neighborhood: 'South Tuxedo Park', npu: 'B' },
  { neighborhood: 'Southwest', npu: 'R' },
  { neighborhood: 'Springlake', npu: 'C' },
  { neighborhood: 'State Facility', npu: 'W' },
  { neighborhood: 'Summerhill', npu: 'V' },
  { neighborhood: 'Swallow Circle/Baywood', npu: 'Z' },
  { neighborhood: 'Sweet Auburn', npu: 'M' },
  { neighborhood: 'Sylvan Hills', npu: 'X' },
  { neighborhood: 'Tampa Park', npu: 'P' },
  { neighborhood: 'The Villages at Carver', npu: 'Y' },
  { neighborhood: 'The Villages at Castleberry Hill', npu: 'T' },
  { neighborhood: 'The Villages at East Lake', npu: 'O' },
  { neighborhood: 'Thomasville Heights', npu: 'Z' },
  { neighborhood: 'Tuxedo Park', npu: 'A' },
  { neighborhood: 'Underwood Hills', npu: 'D' },
  { neighborhood: 'Venetian Hills', npu: 'S' },
  { neighborhood: 'Vine City', npu: 'L' },
  { neighborhood: 'Virginia Highland', npu: 'F' },
  { neighborhood: 'Washington Park', npu: 'K' },
  { neighborhood: 'Wesley Battle', npu: 'C' },
  { neighborhood: 'Westmont Estates', npu: 'S' },
  { neighborhood: 'West End', npu: 'T' },
  { neighborhood: 'West Highlands', npu: 'G' },
  { neighborhood: 'West Lake', npu: 'J' },
  { neighborhood: 'West Manor', npu: 'I' },
  { neighborhood: 'West Paces Ferry/Northside', npu: 'A' },
  { neighborhood: 'Westhaven', npu: 'I' },
  { neighborhood: 'Westminster/Milar', npu: 'C' },
  { neighborhood: 'Westover Plantation', npu: 'C' },
  { neighborhood: 'Westview', npu: 'T' },
  { neighborhood: 'Westwood Terrace', npu: 'I' },
  { neighborhood: 'Whitewater Creek', npu: 'A' },
  { neighborhood: 'Whittier Mill Village', npu: 'D' },
  { neighborhood: 'Wildwood (NPU-C)', npu: 'C' },
  { neighborhood: 'Wildwood (NPU-H)', npu: 'H' },
  { neighborhood: 'Wildwood Forest', npu: 'P' },
  { neighborhood: 'Wilson Mill Meadows', npu: 'H' },
  { neighborhood: 'Wisteria Gardens', npu: 'H' },
  { neighborhood: 'Woodfield', npu: 'C' },
  { neighborhood: 'Woodland Hills', npu: 'W' },
  { neighborhood: 'Wyngate', npu: 'C' },
  { neighborhood: 'Non-Atlanta', npu: 'Non-Atlanta' },
  { neighborhood: "Don't know", npu: "Don't know" },
];
export const npuList = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Non-Atlanta',
  "Don't know",
];
