/* eslint-disable */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import promoCodeReducer, { initialState } from '../reducers/promoCodeReducer';

export const PromoCodeContext = createContext();

const PromoCodeProvider = ({ children }) => {
  const usePromoCodeState = useReducer(promoCodeReducer, initialState);
  return (
    <PromoCodeContext.Provider value={[...usePromoCodeState]}>
      <ContextDevTool
        context={PromoCodeContext}
        id="PromoCodeContext"
        displayName="PromoCode Context"
      />
      {children}
    </PromoCodeContext.Provider>
  );
};

PromoCodeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PromoCodeProvider;
