import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4860CF',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4D507B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontSize: 28,
      fontWeight: 700,
      color: '#4D507B',
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      color: '#8185B9',
    },
    h3: {
      fontSize: 21,
      fontWeight: 700,
      color: '#4D507B',
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
      color: '#353755',
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
      color: '#353755',
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      color: '#4D507B',
      fontStyle: 'italic',
      lineHeight: '20px',
      textAlign: 'center',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: '#4860CF',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      color: '#4860CF',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: '#7D7D7D',
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      color: '#7D7D7D',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
        padding: '11px 15px',
        fontSize: 14,
        fontWeight: 400,
        '@media (max-width:960px)': {
          borderRadius: 20,
          padding: '8px 10px',
          fontSize: 12,
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
        '&:active': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 18,
        color: '#96A1BC',
        '@media (max-width:320px)': {
          fontSize: 14,
        },
      },
      shrink: {
        fontSize: 20,
        color: '#4860CF',
        fontWeight: 700,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 18,
        color: '#353755',
      },
      multiline: {
        border: '2px solid rgba(187, 187, 187, 1)',
        borderBottom: 'none',
        padding: '5px',
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '2px solid rgba(187, 187, 187, 1)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 20,
        fontWeight: 400,
        color: '#353755',
      },
    },
    MuiDialogContent: {
      root: {
        overflow: 'auto',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#4860CF',
        '&$checked': {
          color: '#4860CF',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#4860CF',
        '&$checked': {
          color: '#4860CF',
        },
      },
    },
    MuiDialog: {
      '& .MuiToolbar-root': {
        width: '100%',
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderTopWidth: 3,
      },
      /* line: {
        borderColor: '#4860CF',
      },
      alternativeLabel: {
        left: 'calc(-50% + 11px)',
        right: 'calc(50% + 11px)',
      }, */
    },
    MuiSelect: {
      icon: {
        color: '#4860CF',
      },
    },
    MuiTableCell: {
      root: {
        padding: '2px 16px',
        fontSize: '16px',
        color: '#353755',
      },
    },
    MuiAutocomplete: {
      option: {
        '&[data-focus="true"]': {
          backgroundColor: 'transparent',
        },
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
        '&[data-option-index]': {
          paddingLeft: '4px !important',
        },
      },
    },
    MuiTabs: {
      scroller: {
        background: '#4860CF',
      },
      scrollButtons: {
        '&:not([aria-disabled="false"])': {
          display: 'none',
        },
      },
      flexContainer: {
        background: '#4860CF',
        padding: '0 20px',
        '& .MuiTab-root': {
          background: '#A3AFE7',
          fontSize: 18,
          color: '#4D507B',
          textTransform: 'none',
          margin: '10px 5px 0 5px',
          borderRadius: '5px 5px 0 0',
          paddingTop: '0',
          paddingBottom: '0',
          minHeight: '40px',
          position: 'relative',
          overflow: 'visible',
          '& .MuiTouchRipple-root': {
            zIndex: 1,
            // display: 'none', // commented by Faisal K as ripple effect was not working
          },
        },
        '& .Mui-selected': {
          background: '#fff',
          color: '#4860CF',
          fontWeight: 700,
        },
        '& .MuiTab-root:before': {
          content: '""',
          position: 'absolute',
          height: '10px',
          width: '20px',
          bottom: 0,
          left: '-20px',
          borderRadius: '0 0 10px 0',
          MozBorderRadius: '0 0 10px 0',
          WebkitBorderRadius: '0 0 10px 0',
          WebkitBoxShadow: '10px 0 0 0 #A3AFE7',
          boxShadow: '10px 0 0 0 #A3AFE7',
        },
        '& .MuiTab-root:after': {
          content: '""',
          position: 'absolute',
          height: '10px',
          width: '20px',
          bottom: 0,
          right: '-20px',
          borderRadius: '0 0 0 10px',
          MozBorderRadius: '0 0 0 10px',
          WebkitBorderRadius: '0 0 0 10px',
          WebkitBoxShadow: '-10px 0 0 0 #A3AFE7',
          boxShadow: '-10px 0 0 0 #A3AFE7',
        },
        '& .Mui-selected:before': {
          WebkitBoxShadow: '10px 0 0 0 #fff',
          boxShadow: '10px 0 0 0 #fff',
        },
        '& .Mui-selected:after': {
          WebkitBoxShadow: '-10px 0 0 0 #fff',
          boxShadow: '-10px 0 0 0 #fff',
        },
      },
      indicator: {
        display: 'none',
      },
    } /* 
    MuiPopover: {
      paper: {
        width: 500,
      },
    }, */,
  },
});

export default theme;
