import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import studioReducer, { initialState } from '../reducers/studioReducer';

export const StudioContext = createContext();

const StudioProvider = ({ children }) => {
  const useCompaniesState = useReducer(studioReducer, initialState);

  return (
    <StudioContext.Provider value={[...useCompaniesState]}>
      <ContextDevTool
        context={StudioContext}
        id="CompaniesContext"
        displayName="Companies Context"
      />
      {children}
    </StudioContext.Provider>
  );
};

StudioProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StudioProvider;
