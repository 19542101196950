import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import useStyles from './Loader.styles';

const Loader = ({ txt = null }) => {
  const classes = useStyles();
  return (
    <Modal open BackdropProps={{ style: { backgroundColor: 'rgba(255,255,255,0.9)' } }}>
      <div className={classes.loaderouter}>
        <img src={require('../../assets/loader/loader.gif').default} alt="Loader" />
        <span>{txt || 'Loading...'}</span>
      </div>
    </Modal>
  );
};

Loader.propTypes = {
  txt: PropTypes.string.isRequired,
};

export default Loader;
