import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import studioListReducer, { initialState } from '../reducers/studioListReducer';

export const StudioListContext = createContext();

const StudioListProvider = ({ children }) => {
  const useStudioListState = useReducer(studioListReducer, initialState);

  return (
    <StudioListContext.Provider value={[...useStudioListState]}>
      <ContextDevTool
        context={StudioListContext}
        id="StudioListContext"
        displayName="StudioList Context"
      />
      {children}
    </StudioListContext.Provider>
  );
};

StudioListProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StudioListProvider;
