/* eslint-disable react/prop-types */
import React, { lazy, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Auth = lazy(() => import('../screens/Auth'));
const Login = lazy(() => import('../screens/Auth/Login'));
const CheckIn = lazy(() => import('../screens/Auth/Login/CheckIn'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));

const PublicRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.token && cookies.user) {
      navigate('./app');
    }
  }, [cookies.token, cookies.user, navigate]);

  return <Component {...props} />;
};

const authNav = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgotPassword',
    element: <ForgotPassword />,
  },
  {
    path: 'resetPassword/:token',
    element: <ResetPassword />,
  },
  {
    path: 'check-in/:fitnessSessionId',
    element: <CheckIn />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
  {
    path: '/:company/:roleId/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
