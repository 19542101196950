import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
    backgroundColor: '#4860CF',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  oops: {
    width: '100%',
  },
  msg: {
    fontSize: 24,
    fontWeight: 700,
    color: '#fff',
  },
  disconnect: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  labeltext: {
    fontSize: 24,
    fontWeight: 700,
    color: '#fff',
    textAlign: 'left',
    marginBottom: theme.spacing(0),
    paddingLeft: theme.spacing(5),
  },
  fournotfour: {
    width: '100%',
  },
}));
