/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-case-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
  API_EVENTS,
  GET_STAFF_USERS,
  GET_STUDIO_ADDITIONAL_DATA,
  LOAD_COMPANY_TAX,
} from '../constants/actionTypes';

export const initialState = {
  loading: false,
  data: null,
  companyData: null,
  additionalData: null,
  staffUser: null,
  error: null,
};

const studioReducer = (state, { type, payload }) => {
  console.log('Reducer', payload);
  switch (type) {
    case API_EVENTS.REQUEST:
      return { ...state, loading: true };

    case API_EVENTS.SUCCESS: {
      return { ...state, loading: false, data: payload };
    }

    case API_EVENTS.DONE: {
      return { ...state, loading: false };
    }

    case API_EVENTS.FAIL:
      return { ...state, loading: false, error: payload };

    case LOAD_COMPANY_TAX.TAX_REQUEST:
      return { ...state, loading: true };

    case LOAD_COMPANY_TAX.TAX_SUCCESS: {
      return { ...state, loading: false, companyData: payload };
    }

    case LOAD_COMPANY_TAX.TAX_FAIL:
      return { ...state, loading: false, error: payload };

    case GET_STUDIO_ADDITIONAL_DATA.DATA_REQUEST:
      return { ...state, loading: true };

    case GET_STUDIO_ADDITIONAL_DATA.DATA_SUCCESS: {
      return { ...state, loading: false, additionalData: payload };
    }

    case GET_STUDIO_ADDITIONAL_DATA.DATA_FAIL:
      return { ...state, loading: false, error: payload };

    case API_EVENTS.RESET:
      return initialState;

    case GET_STAFF_USERS.STAFF_REQUEST:
      return { ...state, loading: true };

    case GET_STAFF_USERS.STAFF_SUCCESS:
      return { ...state, loading: false, staffUser: payload };

    case GET_STAFF_USERS.STAFF_FAIL:
      return { ...state, loading: false, error: payload };

    case 'DELETE_STUDIO_HOURS':
      const newData = [];
      for (let i = 0; i < state.additionalData.studioHours.length; i++) {
        const d = state.additionalData.studioHours[i];
        for (const key in d) {
          for (const keys in d[key]) {
            if (d[key][keys]._id !== payload) {
              newData.push(d[key][keys]);
            }
          }
        }
      }
      const groupdedHour = {};
      const newGArr = [];
      newData.reduce((r, a) => {
        groupdedHour[a.day] = groupdedHour[a.day] || [];
        groupdedHour[a.day].push(a);
      }, {});
      newGArr.push(groupdedHour);
      return {
        ...state,
        loading: false,
        additionalData: {
          ...state.additionalData,
          studioHours: newGArr,
        },
      };

    case 'DELETE_STUDIO_AMENITY':
      return {
        ...state,
        loading: false,
        additionalData: {
          ...state.additionalData,
          amenities: state.additionalData.amenities.filter((x) => x.value !== payload),
        },
      };

    case 'ADD_STUDIO_HOURS': {
      if (state.data) {
        console.log(state.data);
      }
      const groupdedStudioHour = {};
      const newGroupedArr = [];
      payload.studioHours.reduce((r, a) => {
        groupdedStudioHour[a.day] = groupdedStudioHour[a.day] || [];
        groupdedStudioHour[a.day].push(a);
      }, {});
      newGroupedArr.push(groupdedStudioHour);
      payload.studioHours = newGroupedArr;
      console.log('Payload ======', JSON.stringify(payload.studioHours));
      const updateData = { additionalData: payload };
      // eslint-disable-next-line prettier/prettier
      console.log('UpdateData======', updateData);
      return updateData;
    }

    case 'EDIT_STUDIO_HOURS': {
      if (state.data) {
        console.log(state.data);
      }
      const updateData = { additionalData: payload.formValues };
      // eslint-disable-next-line prettier/prettier
      console.log('EditUpdateData======', updateData);
      return updateData;
    }

    case 'ADD_ROOMS': {
      if (state.data) {
        console.log(state.data);
      }
      const updateData = { additionalData: payload };
      // eslint-disable-next-line prettier/prettier
      console.log('EDITROOMS======', updateData);
      return updateData;
      /* return {
        ...state,
        additionalData: {
          ...state.additionalData,
          rooms: state.additionalData.rooms.filter((x) => x.roomName !== payload.roomName),
        },
      }; */
    }

    case 'EDIT_ROOMS': {
      if (state.data) {
        console.log(state.data);
      }
      const updateData = { additionalData: payload };
      // eslint-disable-next-line prettier/prettier
      console.log('EDITROOMS======', updateData);
      return updateData;
    }

    case 'DELETE_STUDIO_ROOM':
      console.log('State====', state.additionalData);
      console.log('payload=====', payload);
      return {
        ...state,
        loading: false,
        additionalData: {
          ...state.additionalData,
          rooms: state.additionalData.rooms.filter((x) => x._id !== payload),
        },
      };

    default:
      return state;
  }
};

export default studioReducer;
