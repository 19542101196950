import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import clientWaiverReducer, { initialState } from '../reducers/clientWaiverReducer';

export const ClientWaiverContext = createContext();

const ClientWaiverProvider = ({ children }) => {
  const useCompaniesState = useReducer(clientWaiverReducer, initialState);

  return (
    <ClientWaiverContext.Provider value={[...useCompaniesState]}>
      <ContextDevTool
        context={ClientWaiverContext}
        id="CompaniesContext"
        displayName="Companies Context"
      />
      {children}
    </ClientWaiverContext.Provider>
  );
};

ClientWaiverProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ClientWaiverProvider;
