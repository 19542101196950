import { STUDIO_EVENTS } from '../constants/actionTypes';

/* eslint-disable no-underscore-dangle */
export const initialState = [];

const studioListReducer = (state, { type, payload }) => {
  console.log({ type, payload });
  switch (type) {
    case STUDIO_EVENTS.LOAD_STUDIO_LIST:
      console.log({ payload });
      return payload;

    case STUDIO_EVENTS.DELETE_STUDIO:
      return state.filter((x) => x._id !== payload);

    default:
      return state;
  }
};

export default studioListReducer;
