import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import theme from './theme';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
